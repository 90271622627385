import React from 'react'
import './Styles.css'

import PeoplePgIcon from './Images/PeoplePgIcon.png'

import P1 from './Images/Saurabh Mittal.jpeg'
import P2 from './Images/Gaurav Goel.jpeg'
import P3 from './Images/Saurabh Mittal.jpeg' 
import P4 from './Images/Saurabh Mittal.jpeg'

import { Link } from 'react-router-dom'

import AOS from 'aos';
import 'aos/dist/aos.css';


function People() {
  return (

    <>
    <div className="BgPeoplePgBanner">

       
        
        <p className='HeadPeoplePg' data-aos='fade-up' data-aos-duration='800'> 
          The people who search for 'people-with-potential'
          </p> 
        
        {/* <p className='SubHeadAboutPg'>  </p> */}

        <div className='AboutPgIconDiv' data-aos='fade-up' data-aos-duration='1000' > 
          <img className='PeoplePgIcon' src={PeoplePgIcon} />        
          </div>



    </div>

    <div className='PeopleListCont'> 
          <div className='PeopleSingle' 
          data-aos='fade-in' data-aos-duration='800'
           >  
            <div className='PicBoxP1'>
                <img className='PicSingle' src={P1} />
               </div>

            <div className='TextBoxP1'> 
              <p className='HeadP1'> Saurabh Mittal </p>
              <p className='HeadP1Des'> CEO </p>
              <p className=' HeadP1Link'> 
                <Link to="https://www.linkedin.com/in/saurabhmittal1383/"> .in </Link> 
              </p>


            </div>   
          </div>

          <div className='PeopleSingle' data-aos='fade-up' data-aos-duration='800'>  
            <div className='PicBoxP1'> 
              <img className='PicSingle' src={P2} />
            </div>

            <div className='TextBoxP1'> 
            <p className='HeadP1'> Gaurav Goel </p>
            <p className='HeadP1Des'> Managing Partner </p>
            <p className='HeadP1Des HeadP1Link'> 
                <Link to="https://www.linkedin.com/in/gaurav-goel-41530a2b/"> .in </Link> 
              </p>

            </div>
          </div>

          <div className='PeopleSingle' data-aos='fade-up' data-aos-duration='800'>  
            <div className='PicBoxP1'> 
              <img className='PicSingle' src="" />
            </div>

            <div className='TextBoxP1'> 
            <p className='HeadP1'> Neha Bansal </p>
            <p className='HeadP1Des'> Director - Operations </p>
            <p className='HeadP1Des HeadP1Link'> 
                <Link to="https://www.linkedin.com/in/neha-bansal-b52658b"> .in </Link> 
              </p>

            </div>
          </div>

          <div className='PeopleSingle' data-aos='fade-up' data-aos-duration='800'>  
            <div className='PicBoxP1'> 
              <img className='PicSingle' src="" />
            </div>

            <div className='TextBoxP1'> 
            <p className='HeadP1'> Reenasri Velmurgan </p>
             <p className='HeadP1Des'> Director - Business Dev. </p>
             <p className='HeadP1Des HeadP1Link'> 
                <Link to="https://www.linkedin.com/in/reena-v-160953235/"> .in </Link> 
              </p>

            </div>
          </div>



            <div className='PeopleSingle' data-aos='fade-up' data-aos-duration='800'>  
            <div className='PicBoxP1'> 
              <img className='PicSingle' src="" />
            </div>

            <div className='TextBoxP1'> 
            <p className='HeadP1'> Jagdeep Kumar Sharma </p>
             <p className='HeadP1Des'> Business Development </p>
             <p className='HeadP1Des HeadP1Link'> 
                <Link to="https://www.linkedin.com/in/sharmajagdeep"> .in </Link> 
              </p>

            </div>
          </div>


         


          <div className='PeopleSingle' data-aos='fade-up' data-aos-duration='800'>  
            <div className='PicBoxP1'> 
              <img className='PicSingle' src="" />
            </div>

            <div className='TextBoxP1'> 
            <p className='HeadP1'> Tanu Sharma </p>
             <p className='HeadP1Des'> Delivery/ Account Management </p>
             <p className='HeadP1Des HeadP1Link'> 
                <Link to="linkedin.com/in/tanu-sharma-6a1b8520a"> .in </Link> 
              </p>

            </div>
          </div>


          <div className='PeopleSingle' data-aos='fade-up' data-aos-duration='800'>  
            <div className='PicBoxP1'> 
              <img className='PicSingle' src="" />
            </div>

            <div className='TextBoxP1'> 
            <p className='HeadP1'> Neharika Ahuja Vimal </p>
             <p className='HeadP1Des'> Delivery/ Account Management </p>
             <p className='HeadP1Des HeadP1Link'> 
                <Link to="https://www.linkedin.com/in/neharika-ahuja-vimal-2b496a34"> .in </Link> 
              </p>

            </div>
          </div>



          <div className='PeopleSingle' data-aos='fade-up' data-aos-duration='800'>  
            <div className='PicBoxP1'> 
              <img className='PicSingle' src="" />
            </div>

            <div className='TextBoxP1'> 
            <p className='HeadP1'> Kanika Singh </p>
             <p className='HeadP1Des'> Delivery/ Account Management </p>
             <p className=' HeadP1Link'> 
                <Link to="https://www.linkedin.com/in/kanikasingh1999"> .in </Link> 
              </p>

            </div>
          </div>




          <div className='PeopleSingle' data-aos='fade-up' data-aos-duration='800'>  
            <div className='PicBoxP1'> 
              <img className='PicSingle' src="" />
            </div>

            <div className='TextBoxP1'> 
            <p className='HeadP1'> Sonal Varshney </p>
             <p className='HeadP1Des'> Delivery/ Account Management </p>
             <p className='HeadP1Des HeadP1Link'> 
                <Link to="https://www.linkedin.com/in/sonal-varshney-319a84227"> .in </Link> 
              </p>

            </div>
          </div>


          <div className='PeopleSingle' data-aos='fade-up' data-aos-duration='800'>  
            <div className='PicBoxP1'> 
              <img className='PicSingle' src="" />
            </div>

            <div className='TextBoxP1'> 
            <p className='HeadP1'> Priyanka Gupta </p>
             <p className='HeadP1Des'> Delivery/ Account Management </p>
             <p className='HeadP1Des HeadP1Link'> 
                <Link to="https://www.linkedin.com/in/priyanka12gupta"> .in </Link> 
              </p>

            </div>
          </div>


          <div className='PeopleSingle' data-aos='fade-up' data-aos-duration='800'>  
            <div className='PicBoxP1'> 
              <img className='PicSingle' src="" />
            </div>

            <div className='TextBoxP1'> 
            <p className='HeadP1'> Dikshant Talwar </p>
             <p className='HeadP1Des'> Delivery/ Account Management </p>
             <p className='HeadP1Des HeadP1Link'> 
                <Link to="https://www.linkedin.com/in/dikshanttalwar"> .in </Link> 
              </p>

            </div>
          </div>



          <div className='PeopleSingle' data-aos='fade-up' data-aos-duration='800'>  
            <div className='PicBoxP1'> 
              <img className='PicSingle' src="" />
            </div>

            <div className='TextBoxP1'> 
            <p className='HeadP1'> Saloni Singh </p>
             <p className='HeadP1Des'> Internal HR </p>
             <p className='HeadP1Des HeadP1Link'> 
                <Link to="https://www.linkedin.com/in/saloni-singh-15aa37212"> .in </Link> 
              </p>

            </div>
          </div>



    
    
    </div> 

  

    
    </>
  )
}



export default People
